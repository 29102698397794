import styled from "@emotion/styled";

export const HeaderWrapper = styled("div")`
  label: HeaderWrapper;
  margin-top: 60px;
`;
export const HeaderTitles = styled("div")`
  label: HeaderTitles;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
`;
export const HeaderTitleSm = styled("span")`
  label: HeaderTitleSm;
  position: absolute;
  top: 18%;
  font-size: 20px;
`;
export const HeaderTitleLg = styled("span")`
  label: HeaderTitleLg;
  position: absolute;
  top: 20%;
  font-size: 100px;
`;
export const HeaderImg = styled("img")`
  label: HeaderImg;
  width: 100%;
  height: 450px;
  margin-top: 80px;
  object-fit: cover;
`;
